import React from 'react';
import './App.css';
import SignUp from './components/auth/signup/index'
import Settings from './components/auth/settings/index';
import Notifications from './components/Notifications/index';

import Login from './components/auth/Login'
import SuperAdminLogin from './components/auth/SuperAdminLogin'
import SuperAdminDashboard from './components/AdminDashboard'
import WareHouseHome from './components/warehouse';
import Dashboard from './components/Dashboard';
import WarehouseBooking from './components/driver/warehouse_booking';

import Header from './common/Header'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store'
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'
import { setCurrentUser } from './store/actions/authAction'
import { setCurrentAdmin } from './store/actions/superadmin-authAction'
import PrivateRoute from './common/PrivateRoute'
import PrivateRouteForAdmin from './common/PrivateRouteForAdmin'
import DriverInfo from './components/driver/DriverInfo'
import BookingInfo from './components/driver/BookingInfo'
import Scan from './components/driver/Scan'
import CheckInOut from './components/driver/CheckInOut'
import { NotificationContainer } from 'react-notifications';
import EditWarehouse from './components/Admin/warehouse/editWarehouse'
import EditCarrier from './components/Admin/carrier'
import PageNotFound from './components/auth/PageNotFound'

function App() {

  if (localStorage.jwtToken) {

    const decoded = jwt_decode(localStorage.getItem("jwtToken"));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem('jwtToken')
    } else {
      setAuthToken(localStorage.getItem("jwtToken"))
      //setUserDetails(decoded.payload.user.company_name);
      store.dispatch(setCurrentUser(decoded))
    }
  }

  if (localStorage.jwtTokenAdmin) {
    const decoded = jwt_decode(localStorage.getItem("jwtTokenAdmin"));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem('jwtTokenAdmin')
    } else {
      setAuthToken(localStorage.getItem("jwtTokenAdmin"))
      //setUserDetails(decoded.user.user_name);
      store.dispatch(setCurrentAdmin(decoded))
    }
  }

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Header />
          <Switch>

            <Route exact
              path="/driver/:id"
              component={DriverInfo}
            />

            <Route exact
              path="/driver"
              component={DriverInfo}
            />
            
            <Route exact
              path="/driver-detail/:id"
              component={BookingInfo}
            />

            <Route exact
              path="/driver-detail"
              component={BookingInfo}
            />

            <Route exact
              path="/driver-confirm/:id"
              component={Scan}
            />

            <Route exact
              path="/driver-confirm"
              component={Scan}
            />

            <PrivateRoute exact
              path="/driver-at-warehouse/:id"
              component={CheckInOut}
            />

            <PrivateRoute exact
              path="/settings"
              component={Settings}
            />

            <PrivateRoute exact
              path="/notifications"
              component={Notifications}
            />

             <Route exact
              path="/sign-up"
              component={SignUp}
            />

            <Route exact
              path="/login"
              component={Login}
            />

            <PrivateRouteForAdmin exact
              path="/superadmin/add_warehouse"
              component={SignUp}
            />
            
            <PrivateRouteForAdmin exact
              path="/superadmin/edit-warehouse/:id"
              component={EditWarehouse}
            />

            <PrivateRouteForAdmin exact
              path="/superadmin/edit-carrier/:id"
              component={EditCarrier}
            />

            <Route exact
              path="/superadmin"
              component={SuperAdminLogin}
            />
            
            <Route exact
              path="/superadmin/login"
              component={SuperAdminLogin}
            />

            <PrivateRouteForAdmin exact
              path="/superadmin/dashboard"
              component={SuperAdminDashboard}
            />

            <PrivateRoute exact
              path="/warehouse"
              component={Dashboard}
            />
            
            <Route exact
              path="/:warehouse_domain"
              component={WarehouseBooking}
            />


            <Route exact
              path="/404"
              component={PageNotFound}
            />

            <Redirect to='/login' />
          </Switch>
        </Router>
        <NotificationContainer />
      </Provider>
    </div>
  );
}

export default App;
