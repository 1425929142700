export const SIGN_UP = "SIGN_UP"
export const SET_CURRENT_USER = "SET_CURRENT_USER"
export const SET_CURRENT_USER_DATA = "SET_CURRENT_USER_DATA"
export const UNSET_CURRENT_USER = "SET_CURRENT_USER"
export const ALREADY_BOOK_APPOINTMENT = "ALREADY_BOOK_APPOINTMENT"
export const SET_LOADER = "SET_LOADER"
export const UNSET_CURRENT_USER_LOGIN_LOADER = "UNSET_CURRENT_USER_LOGIN_LOADER"
export const SET_CURRENT_USER_LOGIN_ERROR = "SET_CURRENT_USER_LOGIN_ERROR"
export const UNSET_CURRENT_USER_LOGIN_ERROR = "UNSET_CURRENT_USER_LOGIN_ERROR"
export const GET_WEEK_DAYS = "GET_WEEK_DAYS"

// Super admin actions
export const SET_SUPERADMIN_USER = "SET_SUPERADMIN_USER"
export const UNSET_SUPERADMIN_USER = "SET_SUPERADMIN_USER"
export const SET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const SET_SUPERADMIN_USER_LOGIN_LOADER = "SET_SUPERADMIN_USER_LOGIN_LOADER"
export const UNSET_SUPERADMIN_USER_LOGIN_LOADER = "UNSET_SUPERADMIN_USER_LOGIN_LOADER"
export const SET_SUPERADMIN_USER_LOGIN_ERROR = "SET_SUPERADMIN_USER_LOGIN_ERROR"
export const UNSET_SUPERADMIN_USER_LOGIN_ERROR = "UNSET_SUPERADMIN_USER_LOGIN_ERROR"
export const GET_WAREHOUSES_DATA = 'GET_WAREHOUSES_DATA';
export const GET_CARRIERS_DATA = 'GET_CARRIERS_DATA';
export const GET_APPOINTMENTS_DATA = 'GET_APPOINTMENTS_DATA';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';
export const DELETE_CARRIER = 'DELETE_CARRIER';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const UPDATE_CARRIER = 'UPDATE_CARRIER';
export const GET_CUSTOMERS_DATA = 'GET_CUSTOMERS_DATA';





// Driver actions
export const GET_SINGLE_APPOINTMENT = "GET_SINGLE_APPOINTMENT"
export const CLEAR_SINGLE_APPOINTMENT = 'CLEAR_SINGLE_APPOINTMENT'
export const DISPLAY_CAR_INFORMATION = "DISPLAY_CAR_INFORMATION"
export const LIST_OF_DRIVER = "LIST_OF_DRIVER"
export const GET_SINGLE_CARRIER = "GET_SINGLE_CARRIER"


// warehouse 
export const GET_SINGLE_WAREHOUSE = "GET_SINGLE_WAREHOUSE"


// Appointments
export const SEARCH_APPOINTMENT_HAPPENING = 'SEARCH_APPOINTMENT_HAPPENING';
export const SEARCH_APPOINTMENT_BY_LOT_VIN = 'SEARCH_APPOINTMENT_BY_LOT_VIN';
export const RESET_SEARCH_APPOINTMENT = 'RESET_SEARCH_APPOINTMENT';
export const MARKED_DAY_AS_HOLIDAY = 'MARKED_DAY_AS_HOLIDAY';
export const REMOVED_DAY_AS_HOLIDAY = 'REMOVED_DAY_AS_HOLIDAY';
export const MARKED_DAY_AS_BLOCKED = 'MARKED_DAY_AS_BLOCKED';
export const REMOVED_DAY_AS_BLOCKED = 'REMOVED_DAY_AS_BLOCKED';
export const SAVE_APPT = 'SAVE_APPT'
export const SAVE_DRIVER = 'SAVE_DRIVER'
export const CLEAR_APPT_DATA = 'CLEAR_APPT_DATA'
export const CLEAR_DISPLAY_INFO = 'CLEAR_DISPLAY_INFO'

// Driver Appointments
export const DRIVER_BOOKING_WAREHOUSE_DETAILS = 'DRIVER_BOOKING_WAREHOUSE_DETAILS';
export const WAREHOUSE_APPOINTMENT_DETAILS_FOR_DRIVER_BOOKING = 'WAREHOUSE_APPOINTMENT_DETAILS_FOR_DRIVER_BOOKING';


// Notifications
export const GET_ALERT_NOTIFICATIONS = 'GET_ALERT_NOTIFICATIONS';
export const MARK_NOTIFICATONS_AS_SEEN = 'MARK_NOTIFICATONS_AS_SEEN';

//Customers

export const CUSTOMERS_LIST = 'CUSTOMERS_LIST'
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const CUSTOMER_EXIST = 'CUSTOMER_EXIST'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const SET_LOADING = 'SET_LOADING'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER '
export const CUSTOMER_LIMIT = 'CUSTOMER_LIMIT'
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'